import * as util from '../util.mjs';


/** 
 * Logic that allows for users to subscribe to the blog.
 */

/**
 * Populate the input elements contained in the given full-fledged blog subscription form that 
 * submits to the third-party emailing service (i.e., MailChimp).
 */
export const populateSubscribeForm = function(subscribeSvcFormEle) {
	if (subscribeSvcFormEle) {
    	let emailInputEle = subscribeSvcFormEle.querySelector('[name="EMAIL"]');
    	let consentCtxInputEle = subscribeSvcFormEle.querySelector('[name="CSENT_CTX"]');
    	let consentUrlInputEle = subscribeSvcFormEle.querySelector('[name="CSENT_URL"]');
		
		let consentUrl = null;

		let storage = window.sessionStorage;
		const queryStr = window.location.search;
		/*
		 * The "src=post" querystring will be found when the referrer is the simplified, 
		 * email-address-only blog-subscribe form that is placed on each post. In this case, the 
		 * user probably already supplied their email address. In addition, we want to record the 
		 * referring post as the source of the initial consent (opt-in #1).
		 */
		if (queryStr.indexOf('src=post') > -1) {
		    const subscribeEmailAddress = storage.getItem('subscribeEmailAddress');
		    emailInputEle.value = subscribeEmailAddress;

		    const subscribePageTitle = storage.getItem('subscribePageTitle');
		    consentCtxInputEle.value = subscribePageTitle;

		    //console.log('subscribeEmailAddress = %o, subscribePageTitle = %o', subscribeEmailAddress, subscribePageTitle);

		    consentUrl = storage.getItem('subscribePageUrl');
		} else {
			/*
			 * If the user is navigating to the full-fledged blog subscription page from the 
			 * site navigation menu, clear out any post-referrer-supplied data from session storage.
			 */
		    storage.removeItem('subscribeEmailAddress');
		    storage.removeItem('subscribePageTitle');
		    storage.removeItem('subscribePageUrl');

		    /*
		     * In the markup, the URL for the full-fledged blog subscription page has already been 
		     * set as the consent URL.
		     */
		    consentUrl = consentUrlInputEle.value;
		}

		/*
		 * MailChimp will error if the TLD-less "localhost" is passed as the domain in a 
		 * website/URL field value.
		 */
	    consentUrl = consentUrl.replace('http://localhost:', 'http://127.0.0.1:');
	    //console.log('consentUrl = %o', consentUrl);
	    consentUrlInputEle.value = consentUrl;

	    let nowDate = new Date();
	    // MailChimp wants dates formatted as MM/DD/YYYY
	    const currentDateStr = 
	    	util.padStart((nowDate.getMonth() + 1), 2) + '/' + 
	    	util.padStart(nowDate.getDate(), 2) + '/' + 
	    	nowDate.getFullYear();
	    const consentDateInputEle = subscribeSvcFormEle.querySelector('[name="CSENT_DATE"]');
	    consentDateInputEle.value = currentDateStr;
	}
};

/**
 * Handle the user clicking the (blog) "subscribe" button on the full-fledged blog subscription 
 * form that submits to the third-party emailing service (i.e., MailChimp).
 */
export const handleClickBlogSubscribeWithService = function(event) {
	//console.log('event = %o', event);

	const subscribeFormEle = event.target.parentNode.parentNode;

	const categoryCheckboxEles = subscribeFormEle.querySelectorAll('input[type="checkbox"]');
	const categoryCheckboxElesArr = Array.from(categoryCheckboxEles);
	const atLeastOneCategoryChecked = categoryCheckboxElesArr.some(function(oneCheckboxEle, idx, arr) {
		return oneCheckboxEle.checked === true;
	});
	//console.log('atLeastOneCategoryChecked = %o', atLeastOneCategoryChecked);

	/*
	 * Require that at least one post category is selected. This will have no effect in IE v9. As 
	 * such, subscriptions submitted from that browser may have no category selected.
	 * More info about the constraint validation API: 
	 *	https://developer.mozilla.org/en-US/docs/Web/API/Constraint_validation
	 */
	let allCheckboxInputEle = document.getElementById('mce-group[8853]-8853-0');
	if (!atLeastOneCategoryChecked) {
		// Setting a custom validity will prevent the form submission.
		allCheckboxInputEle.setCustomValidity('At least one category must be selected.');
	} else {
		allCheckboxInputEle.setCustomValidity('');
		allCheckboxInputEle.reportValidity();
	}
};
