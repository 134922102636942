
/**
 * Logic globally related to the site, both pages and posts.
 */

/**
 * Update the end date in the site copyright notice.
 */
export const updateCopyrightEndDate = function() {
	const currentYear = new Date().getFullYear();
	const endYearEle = document.getElementById('copyright-end-year');
	endYearEle.textContent = currentYear;
};
